import dayjs from "dayjs";
import "dayjs/locale/de";
import { useEffect, useState } from "react";
import { HeroChevronLeft, HeroChevronRight } from "./heroicons";
import customParseFormat from "dayjs/plugin/customParseFormat";
import "dayjs/locale/de";
import { client } from "./../client";

dayjs.extend(customParseFormat);
dayjs.locale("de");

const CONTENTFUL_DATE_FORMAT = "YYYY-MM-DD";

// custom hook for Date Status (only fetch data once)
function useDateStatus(date) {
  // store belegungen
  const [belegungen, setBelegungen] = useState();

  // fetch belegungen from contentful
  useEffect(() => {
    client
      .getEntries({
        content_type: "belegung",
        order: "fields.start",
        "fields.end[gte]": dayjs().format("YYYY-MM-DD"),
      })
      .then((response) => {
        let belegungenApi = [];
        response.items.map((i) =>
          belegungenApi.push({
            start: dayjs(i.fields.start, CONTENTFUL_DATE_FORMAT),
            end: dayjs(i.fields.end, CONTENTFUL_DATE_FORMAT),
          })
        );
        setBelegungen(belegungenApi);
      })
      .catch(console.error);
  }, []);

  // return 1 (^= belegt) if no data recieved
  if (belegungen === undefined) {
    return 1;
  }

  // check if belegung matches contains date
  function checkDates(start, end) {
    if (end === undefined) {
      end = start;
    }
    if (start.isAfter(date, "day")) {
      return false;
    }
    if (end.isBefore(date, "day")) {
      return false;
    }
    return true;
  }

  // count belegungen, return 0 if day is free
  return belegungen.filter((b) => checkDates(b.start, b.end)).length;
}

const DisplayDay = ({ date }) => {
  const isFree = useDateStatus(date) === 0;

  return (
    <div className={`py-1.5 ${isFree ? "bg-green-200" : "bg-red-100"}`}>
      <time
        dateTime={date.format()}
        className="flex items-center justify-center mx-auto rounded-full h-7 w-7"
      >
        {date.format("D")}
      </time>
    </div>
  );
};

const DisplayMonth = ({ baseDate }) => {
  const startDayOfWeek = baseDate.date(1).day();
  const numberOfDaysInMonth = baseDate
    .add(1, "month")
    .date(1)
    .subtract(1, "day")
    .date();
  const endDayOfWeek = baseDate.date(numberOfDaysInMonth).day();

  return (
    <section className="text-center">
      <h2 className="font-semibold text-gray-900">
        {baseDate.format("MMMM YY")}
      </h2>
      <div className="grid grid-cols-7 mt-6 text-xs leading-6 text-gray-500">
        {[...Array(7).keys()].map((d) => (
          <div key={d}>{dayjs().day(d).format("dd")}</div>
        ))}
      </div>
      <div className="grid grid-cols-7 gap-px mt-2 overflow-hidden text-sm bg-gray-200 rounded-lg shadow isolate ring-1 ring-gray-200">
        {/* skip days at beginning of month */}
        {[...Array(startDayOfWeek).keys()].map((i) => (
          <div key={i} className="bg-gray-100 py-1.5"></div>
        ))}
        {/* fill dates in month */}
        {[...Array(numberOfDaysInMonth).keys()].map((d) => (
          <DisplayDay date={baseDate.date(d + 1)} key={d} />
        ))}
        {/* fill days at end of month */}
        {[...Array(6 - endDayOfWeek).keys()].map((i) => (
          <div key={i} className="bg-gray-100 py-1.5"></div>
        ))}
      </div>
    </section>
  );
};

export default function Calendar() {
  const [baseDate, setBaseDate] = useState(dayjs().date(1));

  return (
    <div className="py-24 layout-container" id="belegungsplan">
      <h2 className="text-3xl font-extrabold tracking-tight text-center text-gray-900 sm:text-4xl">
        Belegungsplan
      </h2>
      <div className="flex bg-white">
        {/* navigation prev */}
        {baseDate.isBefore(dayjs()) ? (
          <span className="w-10 lg:w-16"></span>
        ) : (
          <button
            className="flex items-center text-gray-300 hover:text-gray-400"
            onClick={() => setBaseDate(baseDate.subtract(1, "month"))}
          >
            <span className="sr-only">zurück</span>
            <HeroChevronLeft className="w-10 h-10 text-gray-300 lg:w-16 lg:h-16 hover:text-gray-400" />
          </button>
        )}
        {/* months */}
        <div className="grid flex-1 grid-cols-1 py-16 layout-container sm:grid-cols-2 md:grid-cols-3 gap-x-8 gap-y-16">
          {[...Array(3).keys()].map((key) => (
            <DisplayMonth key={key} baseDate={baseDate.add(key, "month")} />
          ))}
        </div>
        {/* navigation next */}
        <button
          className="flex items-center text-gray-300 hover:text-gray-400"
          onClick={() => setBaseDate(baseDate.add(1, "month"))}
        >
          <span className="sr-only">vor</span>
          <HeroChevronRight className="w-10 h-10 text-gray-300 lg:w-16 lg:h-16 hover:text-gray-400" />
        </button>
      </div>
    </div>
  );
}
