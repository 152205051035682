import ReactMarkdown from "react-markdown";

export default function Article({article}) {
    const { heading, description } = article.fields
    return (
        <div className="max-w-4xl mx-auto flex-1 bg-white p-6 flex flex-col justify-between rounded-lg shadow-lg overflow-hidden">
            <p className="text-xl font-semibold text-gray-900">{heading}</p>
            <div className="mt-3 text-base text-gray-500 prose">
                <ReactMarkdown>{description}</ReactMarkdown>
            </div>
        </div>
    )
}