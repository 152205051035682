import { CheckIcon, XIcon } from '@heroicons/react/solid'

const infoTitel = 'Informationen'
const infoUntertitel = 'Alles Wichtige rund um Ihre Buchung.'
const infos = [
    {
        name: '24/7 Check-In',
        description: 'per Schlüsselsafe',
    },
    {  
        name: 'Check-Out bis 11 Uhr',
        description: 'Hinterlegen sie den Schlüssel im Schlüsselsafe.'
    },
    {
        name: 'Nichtraucher',
        description: '',
    },
    {
        name: 'kostenfreies W-Lan',
        description: ''
    },
    {
        name: 'Parkplatz',
        description: 'Parkmöglichkeiten auf öffentlichen Flächen gegenüber dem Haus.'
    },
    {
        name: 'Langzeitaufenthalt',
        description: 'Sprechen Sie uns an, falls sie mehr als 14 Nächte bei uns bleiben möchten.'
    },
    {
        name: 'Haustiere nicht erlaubt',
        description: '',
    },
]

const ausstattungTitel = 'Ausstattung'
const ausstattungUntertitel = 'Zimmeraufteilung, Ausstattung.'
const ausstattung = [
    {
        name: 'Vollausgestatte Küche',
        description: 'Besteck/Geschirr für 6 Personen, Kaffeemaschine, Wasserkocher, Mikrowelle, Herd, Ofen, Geschirrspüler, Kühlschrank mit Gefrierfach, Toaster, Geschirrtücher',
    },
    {
        name: 'Tageslichtbad',
        description: 'Handtücher, Haartrockner, Waschmaschine, Wäscheständer, Bügelbrett, Bügeleisen',
    },
    {
        name: 'Schlafzimmer 1',
        description: 'Doppelbett (160x200), Bettwäsche, Kleiderschrank, Nachttische, Frisiertisch',
    },
    {
        name: 'Schlafzimmer 2',
        description: 'Einzelbett (90x200), Schlafsofa (80x200), Bettwäsche, Kleiderschrank, Nachttisch, Beistelltisch',
    },
    {
        name: 'Wohnzimmer',
        description: 'Eingangsbereich, Couch, TV-Board mit TV, Couchtisch',
    },
    {
        name: 'Separate Toilette',
        description: '',
    },
    {
        name: 'Garderobe',
        description: '',
    },
    {
        name: 'Abstellraum',
        description: '',
    },
    {
        name: 'Terrasse',
        description: '',
    },
]
  
  export default function Infos() {
    return (
        <div className="bg-gray-50">
            <h2 className="pt-20 pb-12 text-center text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">Info + Ausstattung</h2>
            
            <div className="layout-container py-8 sm:py-16 lg:grid lg:grid-cols-3 lg:gap-x-8">
                <div>
                    <h3 className="text-2xl sm:text-3xl font-extrabold text-gray-900">{infoTitel}</h3>
                    <p className="mt-4 text-lg text-gray-500">{infoUntertitel}</p>
                </div>
                <div className="mt-12 lg:mt-0 lg:col-span-2">
                    <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
                        {infos.map((info, i) => (
                        <div key={i} className="relative">
                            <dt>
                                { info.name === 'Haustiere nicht erlaubt' ?
                                    <XIcon className="absolute w-5 h-5 mt-0.5 text-red-400" aria-hidden="true" />
                                    :
                                    <CheckIcon className="absolute w-5 h-5 mt-0.5 text-primary" aria-hidden="true" />
                                }
                                <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{info.name}</p>
                            </dt>
                            <dd className="mt-2 ml-9 text-base text-gray-500">{info.description}</dd>
                        </div>
                        ))}
                    </dl>
                </div>
            </div>
            
            <hr className="layout-container" />

            <div className="layout-container py-8 sm:py-16 lg:grid lg:grid-cols-3 lg:gap-x-8">
                <div>
                    <h3 className="text-2xl sm:text-3xl font-extrabold text-gray-900">{ausstattungTitel}</h3>
                    <p className="mt-4 text-lg text-gray-500">
                        {ausstattungUntertitel}
                    </p>
                </div>
                <div className="mt-12 lg:mt-0 lg:col-span-2">
                    <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
                        {ausstattung.map((merkmal, i) => (
                        <div key={i} className="relative">
                            <dt>
                                <CheckIcon className="absolute w-5 h-5 text-primary" aria-hidden="true" />
                                <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{merkmal.name}</p>
                            </dt>
                            <dd className="mt-2 ml-9 text-base text-gray-500">{merkmal.description}</dd>
                        </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
  }
  