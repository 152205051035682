import { MailIcon, InformationCircleIcon } from '@heroicons/react/outline'

const prices = [
  {
    name: '1-5 Nächte',
    info: 'zzgl. 40€ Endreinigung',
  },
  {
    name: '6-14 Nächte',
    info: 'inkl. Endreinigung',
  },
  {
    name: 'Langzeitaufenthalt',
  },
]

export default function Pricing() {
  return (
    <div id="preise" className="max-w-4xl mx-auto py-24 px-4 xl:px-0">
        <h2 className="text-center text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">Preisübersicht</h2>
        <div className="text-center my-8">
          <span className="text-4xl font-extrabold text-gray-900">50 €</span>
          <span className="font-medium text-gray-500 ml-1">/ Nacht</span>
          <span className="block font-medium text-gray-500">für bis zu zwei Personen</span>
          <span className="block font-medium mt-6 text-gray-500">Jede weitere Person 10€ / Nacht</span>
        </div>
        <div className="space-y-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0">
            {prices.map((price) => (
              <div key={price.name} className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200">
                  <div className="p-6">
                      <h2 className="text-lg leading-6 font-medium text-gray-900">{price.name}</h2>
                      <p className="mt-8">
                          {price.info ?
                              <span>{price.info}</span>
                          :
                              <a
                                  href="#kontakt"
                                  className="whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-primary hover:bg-primary/90"
                              >
                                <MailIcon className="w-5 h-5 mr-1" />
                                  Kontakt
                              </a>
                          }
                      </p>
                  </div>
              </div>
            ))}
        </div>

        <div className="w-full sm:max-w-md mx-auto my-8">
          <div className="bg-primary/5 border border-primary shadow rounded-lg p-6">
            <div className="flex items-center space-x-6">
              <InformationCircleIcon className="w-8 h-8 text-primary" />
              <div>
                <ul className="text-base text-gray-800">
                  <li>Kostenlos stornierbar bis 72h vor Ankunft</li>
                </ul>
              </div>
            </div>
            
          </div>
        </div>

    </div>
  )
}
