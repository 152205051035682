import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <footer className="bg-primary text-white">
            <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
                <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
                    <Link to="/impressum" className="block px-5 py-2 text-base hover:text-gray-300">
                        Impressum
                    </Link>
                    <Link to="/datenschutz" className="block px-5 py-2 text-base hover:text-gray-300">
                        Datenschutz
                    </Link>
                </nav>
                <p className="mt-8 text-center text-base text-white">&copy; 2022 fewo-schweitzer. Alle Rechte vorbehalten.</p>
            </div>
        </footer>
    )
}
