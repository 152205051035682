import { useEffect, useState } from "react";
import { client } from "../client";
import Article from "./Article";

export default function Welcome() {
  // keep news in Component
  const [news, setNews] = useState([]);

  // fetch content on page load
  useEffect(() => {
    client
      .getEntries({
        content_type: "aktuelles",
        order: "sys.createdAt",
      })
      .then((response) => {
        setNews(response.items);
      })
      .catch(console.error);
  }, []);

  return (
    <div className="bg-white" id="home">
      <div className="relative h-[600px] md:h-[800px] lg:h-[900px] overflow-hidden">
        <div className="absolute inset-0">
          <img
            className="object-cover w-full h-full"
            src="./images/big/Wohnz-1.jpg"
            alt="Willkommen"
          />
          <div
            className="absolute inset-0 bg-black/25"
            aria-hidden="true"
          />
        </div>
        <div className="absolute bottom-8 sm:bottom-20 inset-x-0 flex flex-col items-center text-white layout-container">
          <h1 className="px-4 py-2 text-3xl font-bold tracking-tight rounded md:text-5xl lg:text-6xl bg-primary sm:inline-block">
            Herzlich Willkommen!
          </h1>
          <p className="inline-block max-w-3xl px-4 py-2 mt-4 text-base rounded sm:text-xl bg-primary">
            Familie Schweitzer begrüßt Sie in unserer Ferienwohnung in
            Bretzfeld, unweit der A6 und A81 im Landkreis Hohenlohe.
          </p>
        </div>
      </div>

      <div className={'relative pb-8 -mt-32 layout-container ' + (news.length > 1 ? 'flex space-x-4' : '') + (news.length === 0 ? 'hidden' : '')}>
        {news?.map((article, key) => (
          <Article article={article} key={key} />
        ))}
      </div>
    </div>
  );
}

// export default function Welcome() {
//     return (
//         <div className="px-4 py-16 layout-container sm:pb-24 sm:pt-32">
//             <div className="text-center">
//             <h2 className="text-base font-semibold tracking-wide uppercase text-primary">fewo bretzfeld</h2>
//             <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
//                 Herzlich Willkommen!
//             </p>
//             <p className="max-w-xl mx-auto mt-5 text-xl text-gray-500">
//                 Wir begrüßen Sie in unserem Gästehaus in Bretzfeld, unweit der A6 und A81 im Landkreis Hohenlohe.
//             </p>
//             </div>
//         </div>
//     )
// }
