import React from "react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Header from "./components/Header";
import Welcome from "./components/Welcome";
import Gallery from "./components/Gallery";
import Infos from "./components/Infos";
import Pricing from "./components/Pricing";
import Calendar from "./components/Calendar";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Impressum from "./components/Impressum"
import Datenschutz from "./components/Datenschutz"

const App = () => {
  return (
    <Router>
      <header className="fixed top-0 z-20 w-full">
        <Header />
      </header>
        <main>
        <Switch>
            <Route exact path="/">
                <Welcome />
                <Gallery />
                <Infos />
                <Pricing />
                <Calendar />
                <Contact />
            </Route>
            <Route path="/impressum">
                <Impressum />
            </Route>
            <Route path="/datenschutz">
                <Datenschutz />
            </Route>
        </Switch>
        </main>
      <footer>
        <Footer />
      </footer>
    </Router>
  );
};

export default App;
