  export default function Impressum() {
    return (
        <div className="relative pt-40 pb-16 layout-container prose">
            <h1>Impressum</h1>

            <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
            <p>Regina + Michael Schweitzer<br />
                Badstr. 30<br />
                74626 Bretzfeld</p>

            <h2>Kontakt</h2>
            <p>Telefon: 0173 9705056<br />
                E-Mail: rm.schweitzer@web.de</p>

            <h2>Realisierung und Design</h2>
            <p>Webdesign Wiessner<br />
                <a href="https://kaiwiessner.de" rel="noopener noreferrer" target="_blank">kaiwiessner.de</a>
            </p>

            <h2>EU-Streitschlichtung</h2>
            <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

            <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
            <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

            <p>Quelle: <a href="https://www.e-recht24.de/impressum-generator.html">https://www.e-recht24.de/impressum-generator.html</a></p>
        </div>
    )
  }
  