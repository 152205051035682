import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { HomeIcon, CalendarIcon, CurrencyEuroIcon, LightBulbIcon, MenuIcon, XIcon } from '@heroicons/react/outline'

export default function Header() {
  return (
    <Popover className="relative bg-white">
      <div className="layout-container">
        <div className="flex justify-between items-center md:space-x-10 py-2">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <a href="./#home">
              <span className="sr-only">Fewo Schweitzer</span>
              <img src="./logo.png" className="max-h-" width="60" height="60" alt="logo"  />
            </a>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary">
              <span className="sr-only">Menü öffnen</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden md:flex space-x-10">

            <a href="./#home" className="flex items-center text-base font-medium text-gray-500 hover:text-gray-900">
                <HomeIcon className="h-5 w-5 text-primary"/>
                <span className="pl-1 pt-0.5">Home</span>
            </a>
            <a href="./#wohnung" className="flex items-center text-base font-medium text-gray-500 hover:text-gray-900">
                <LightBulbIcon className="h-5 w-5 text-primary"/>
                <span className="pl-1 pt-0.5">Wohnung</span>
            </a>
            <a href="./#preise" className="flex items-center text-base font-medium text-gray-500 hover:text-gray-900">
                <CurrencyEuroIcon className="h-5 w-5 text-primary"/>
                <span className="pl-1 pt-0.5">Preise</span>
            </a>
            <a href="./#belegungsplan" className="flex items-center text-base font-medium text-gray-500 hover:text-gray-900">
                <CalendarIcon className="h-5 w-5 text-primary"/>
                <span className="pl-1 pt-0.5">Belegungsplan</span>
            </a>
          </Popover.Group>

          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            <a
              href="./#kontakt"
              className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-primary hover:bg-primary"
            >
              Kontakt
            </a>
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <span className="text-primary">fewo-schweitzer</span>
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary">
                    <span className="sr-only">Menü schließen</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>

            </div>
            <div className="flex flex-col text-center py-6 px-5 space-y-6">

              <Popover.Button>
                <a href="./#home" className="text-base font-medium text-gray-900 hover:text-gray-700">
                 Home
                </a>
              </Popover.Button>

              <Popover.Button>
                <a href="./#wohnung" className="text-base font-medium text-gray-900 hover:text-gray-700">
                  Wohnung
                </a>
              </Popover.Button>

              <Popover.Button>
                <a href="./#preise" className="text-base font-medium text-gray-900 hover:text-gray-700">
                  Preise
                </a>
              </Popover.Button>

              <Popover.Button>
                <a href="./#belegungsplan" className="text-base font-medium text-gray-900 hover:text-gray-700">
                  Belegungsplan
                </a>
              </Popover.Button>

              <div className="flex justify-center">
                <Popover.Button>
                  <a href="./#kontakt" className="whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-primary hover:bg-primary">
                    Kontakt
                  </a>
                </Popover.Button>
              </div>
            </div>
            </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
