import { Fragment, useReducer, useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { HeroChevronLeft, HeroChevronRight, HeroClose } from "./heroicons";

const ARROW_LEFT_KEYS = ["37", "ArrowLeft"];
const ARROW_RIGHT_KEYS = ["39", "ArrowRight"];

const imageList = [
	{
		caption: "Wohnzimmer",
		thumbUrl:
			"./images/small/Wohnz-1.jpg",
		imageUrl:
			"./images/big/Wohnz-1.jpg",
	},
	{
		caption: "Wohnzimmer",
		thumbUrl:
			"./images/small/Wohnz-5.jpg",
		imageUrl:
			"./images/big/Wohnz-5.jpg",
	},
	{
		caption: "WC",
		thumbUrl:
			"./images/small/WC.jpg",
		imageUrl:
			"./images/big/WC.jpg",
	},
	{
		caption: "Terrasse",
		thumbUrl:
			"./images/small/Terrasse.jpg",
		imageUrl:
			"./images/big/Terrasse.jpg",
	},
	{
		caption: "Schlafzimmer 1",
		thumbUrl:
			"./images/small/Schlafz-1-2.jpg",
		imageUrl:
			"./images/big/Schlafz-1-2.jpg",
	},
	{
		caption: "Schlafzimmer 1",
		thumbUrl:
			"./images/small/Schlafz-1-3.jpg",
		imageUrl:
			"./images/big/Schlafz-1-3.jpg",
	},
	{
		caption: "Schlafzimmer 2",
		thumbUrl:
			"./images/small/Schlafz-2-2.jpg",
		imageUrl:
			"./images/big/Schlafz-2-2.jpg",
	},
	{
		caption: "Schlafzimmer 2",
		thumbUrl:
			"./images/small/Schlafz-2-3.jpg",
		imageUrl:
			"./images/big/Schlafz-2-3.jpg",
	},
	{
		caption: "Küche",
		thumbUrl:
			"./images/small/Küche-2.jpg",
		imageUrl:
			"./images/big/Küche-2.jpg",
	},
	{
		caption: "Küche",
		thumbUrl:
			"./images/small/Küche-3.jpg",
		imageUrl:
			"./images/big/Küche-3.jpg",
	},
	{
		caption: "Bad",
		thumbUrl:
			"./images/small/Bad-5.jpg",
		imageUrl:
			"./images/big/Bad-5.jpg",
	},
	{
		caption: "Flur",
		thumbUrl:
			"./images/small/Flur-2.jpg",
		imageUrl:
			"./images/big/Flur-2.jpg",
	},
];

const Gallery = () => {
	return (
		<div id="wohnung" className="layout-container py-24">
			<div className="space-y-12">
				<div className="space-y-5 sm:space-y-4">
					<h2 className="text-3xl font-extrabold tracking-tight text-center text-gray-900 sm:text-4xl">
						Die Wohnung
					</h2>
					<img
						src="./grundriss.png"
						className="block mx-auto pt-6 pb-12"
						width="458"
						height="262"
						alt="Grundriss Wohnung"
					/>
					<p className="max-w-4xl mx-auto text-lg text-gray-500">
						<span className="block pb-2">
							Unsere 2021 renovierte Ferienwohnung liegt in einer Wohnsiedlung
							von Scheppach, nahe der S-Bahn Haltestelle, die in nur 4
							Gehminuten zu erreichen ist.
						</span>
						<span className="block pb-2">
							Scheppach ist ein Teilort der Großgemeinde Bretzfeld und liegt
							sehr verkehrsgünstig nur 3 km vom Autobahnanschluss der A6
							entfernt.
						</span>
						<span className="block pb-2">
							Die vollausgestattete Wohnung im Erdgeschoss mit ihren 70 m²
							Wohnfläche und Terrasse bietet viel Platz für 2 bis 4 Personen.
							<br />
							Der sonnige Sitzbereich auf der Terrasse mit Blick auf den Garten
							lädt im Sommer gerne zum Verweilen ein.
						</span>
						<span className="block pb-2">
							Die Wohnung, umgeben von Wald, Weinbergen, Wander- und
							Fahrradwegen ist somit sehr gut geeignet für Urlauber und Pendler.
						</span>
						<span className="block pb-2">
							Eingang über die Terrasse auf der Rückseite des Hauses.
						</span>
						<span className="block pb-2">
							Nehmen Sie sich etwas Zeit, um unsere Ferienwohnung auf den
							Bildern besser kennen zu lernen.
						</span>
					</p>
					<ImageGallery images={imageList} />
				</div>
			</div>
		</div>
	);
};

const initialState = {
	list: imageList,
	selected: undefined,
	index: undefined,
	isOpen: false,
};

function handleGallery(state, action) {
	// close lightbox
	function close() {
		return { ...state, isOpen: false };
	}

	// set image and open lightbox if not open
	function setImage(i) {
		return {
			...state,
			isOpen: true,
			index: i,
			selected: state.list[i],
			hasPrev: i !== 0,
			hasNext: i !== state.list.length - 1,
		};
	}

	switch (action.type) {
		case "close":
			return close();
		case "open":
			return setImage(action.index);
		case "prev":
			if (!state.hasPrev) {
				return close();
			}
			return setImage(state.index - 1);
		case "next":
			if (!state.hasNext) {
				return close();
			}
			return setImage(state.index + 1);
		default:
			throw new Error();
	}
}

// listen for key press
// https://thewebdev.info/2021/05/24/how-to-listen-for-key-press-for-document-in-react-js/
const useEventListener = (eventName, handler, element = window) => {
	const savedHandler = useRef();

	useEffect(() => {
		savedHandler.current = handler;
	}, [handler]);

	useEffect(() => {
		const eventListener = (event) => savedHandler.current(event);
		element.addEventListener(eventName, eventListener);
		return () => {
			element.removeEventListener(eventName, eventListener);
		};
	}, [eventName, element]);
};

const ImageGallery = ({ images }) => {
	// image gallery state
	const [state, dispatch] = useReducer(handleGallery, initialState);

	// short to close
	function closeModal() {
		dispatch({ type: "close" });
	}

	// short to select image by index
	function selectImage(index) {
		dispatch({ type: "open", index: index });
	}

	// debug
	/*
	useEffect(() => {
		console.log(state);
	}, [state]);
	*/

	// navigate with arrow keys
	const handleKeyDown = ({ key }) => {
		if (state.isOpen) {
			if (ARROW_LEFT_KEYS.includes(String(key))) {
				dispatch({ type: "prev" });
			}
			if (ARROW_RIGHT_KEYS.includes(String(key))) {
				dispatch({ type: "next" });
			}
		}
	};
	useEventListener("keydown", handleKeyDown);

	return (
		<>
			{/* Gallery */}
			<ul className="grid grid-cols-2 sm:grid-cols-3 gap-4 sm:gap-x-6 sm:gap-y-8 sm:space-y-0 lg:grid-cols-4 lg:gap-x-8 pt-12">
				{images.map((element, i) => (
					<li key={element.caption} onClick={() => selectImage(i)}>
						<div className="cursor-pointer shadow-md hover:shadow-lg rounded-lg overflow-hidden">
							<div className="aspect-w-3 aspect-h-2">
								<img
									className="object-cover"
									src={element.thumbUrl}
									alt=""
								/>
							</div>
							<span className="space-y-1 text-lg font-medium leading-6 sr-only">
								{element.caption}
							</span>
						</div>
					</li>
				))}
			</ul>

			{/* Overlay */}
			<Transition appear show={state.isOpen} as={Fragment}>
				<Dialog as="div" className="relative z-30" onClose={closeModal}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black bg-opacity-50" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex items-center justify-center min-h-full p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								{/* Panel mit Bugfix (margin-bottom): sonst hairline wegen Rundungsdifferenz bei aspect-ratio */}
								<Dialog.Panel
									style={{ marginBottom: "-1px" }}
									className="w-full max-w-6xl overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl"
								>
									<div className="relative aspect-w-3 aspect-h-2">
										<img
											className="object-cover w-full h-full"
											src={state.selected?.imageUrl}
											alt=""
										/>
										{/* caption */}
										<div className="flex">
											<div className="self-end w-full py-2 text-white bg-black bg-opacity-50">
												<Dialog.Title
													as="caption"
													className="block text-lg font-medium leading-6 text-gray-300"
												>
													{state.selected?.caption}
												</Dialog.Title>
											</div>
										</div>
										{/* navigation */}
										<div className="grid w-full h-full grid-cols-2">
											{state.hasPrev && (
												<button
													type="button"
													className="flex items-center col-start-1 px-4 text-white shadow-none outline-none opacity-50 hover:opacity-95"
													onClick={() => dispatch({ type: "prev" })}
												>
													<span class="sr-only">zurück</span>
													<HeroChevronLeft className="w-6 h-6 lg:w-16 lg:h-16" />
												</button>
											)}
											{state.hasNext && (
												<button
													type="button"
													className="flex items-center justify-end col-start-2 px-4 text-white shadow-none outline-none opacity-50 hover:opacity-95"
													onClick={() => dispatch({ type: "next" })}
												>
													<span class="sr-only">vor</span>
													<HeroChevronRight className="w-6 h-6 lg:w-16 lg:h-16" />
												</button>
											)}
										</div>
										{/* close button */}
										<button
											type="button"
											className="absolute top-0 flex items-center justify-center w-12 h-12 lg:w-24 lg:h-24 text-white opacity-50 hover:opacity-95"
											style={{ left: "100%", transform: "translateX(-100%)" }}
											onClick={closeModal}
										>
											<span className="sr-only">schließen</span>
											<HeroClose className="w-5 h-5 lg:w-12 lg:h-12" />
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	);
};

export default Gallery;
