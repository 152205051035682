export default function Contact() {
    return (
        <div className="bg-white" id="kontakt">
            <div className="layout-container py-16 lg:py-24">
                <div className="divide-y-2 divide-gray-200">
                    <div>
                        <div className="space-y-5 sm:space-y-4">
                            <h2 className="text-center text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">Kontakt</h2>
                            <p className="text-xl text-gray-500 text-center max-w-xl mx-auto">
                                Zögern Sie nicht uns zu kontaktieren.
                                <small className="block mt-2">Bevorzugter Kontakt per E-Mail, gerne aber auch telefonisch.</small>
                            </p>

                        </div>
                        <div>
                            <dl className="mt-8 text-base text-gray-500 flex flex-col items-center justify-center space-y-8">
                                <div className="flex flex-col items-center space-y-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                    </svg>
                                    <dd>fewo-schweitzer@web.de</dd>
                                </div>
                                <div className="flex flex-col text-center items-center mt-4">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                                    </svg>
                                    <div className="mt-1">
                                        <small className="text-primary">ab 16 Uhr</small>
                                        <div>0173 9705056</div>
                                    </div>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
